// Colors
$primary: #1f1f1f;
$dark-blue: #343f7b;
$white: #fff;
$black: #000;
$btn-yellow: #fddb07;
$btn-yellow-hover: #ccb200;

*,
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

html, body {
  height: 100%;
}

a.btn,
.btn {
  color: $primary;
  padding: 10px;
  display: flex;
  background-color: $btn-yellow;
  max-width: 150px;
  justify-content: center;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 3px;
  font-weight: 600;
  transition: background-color 0.15s ease-in-out;
  border: none;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: $btn-yellow-hover;
  }
}

.mobile-only {
  display: block;
}

.desktop-only {
  display: none;
}

.logo {
  img {
    width: 100%;
    max-width: 150px;

    @media only screen and (min-width: 640px) {
      max-width: 200px;
    }

    @media only screen and (min-width: 992px) {
      max-width: 250px;
    }
  }
}

.header {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(31,31,31);
  background: radial-gradient(circle, rgba(31,31,31,1) 0%, rgba(0,0,0,1) 100%);

  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 20px;

    @media only screen and (min-width: 768px) {
      font-size: 30px;
    }

    span {
      visibility: hidden;
      width: 0;
      height: 0;
      display: block;
    }
  }
}

.main-wrapper {
  height: 100%;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;

  h1 {
    color: $dark-blue;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 10px;
  }

  h2 {
    color: $dark-blue;
    font-weight: bold;
    font-family: 'Source Serif Pro', cursive;
    font-size: 26px;
    line-height: 32px;
    padding-bottom: 20px;

    span {
      visibility: hidden;
      width: 0;
      height: 0;
      display: block;
    }
  }

  h3 {
    color: #333;
    font-size: 20px;
    padding-bottom: 20px;
    line-height: 30px;

    &.social-title {
      margin-top: 40px;
      font-size: 18px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  p {
    color: #333;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    @media only screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 36px;
    }
  }

  .btn-github {
    &.fa-square-github {
      font-size: 20px;
    }

    span {
      display: none;
    }
  }

  .inner-content {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  .header {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .logo {
    font-size: 75px;
  }

  .content {
    padding: 40px;

    h1 {
      font-size: 40px;
      line-height: 40px;
      padding-bottom: 20px;
    }
  }
}

.dev-icons {
  display: flex;
  list-style: none;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  flex-wrap: wrap;

  &.social {
    margin-top: 20px;
  }

  .fa-brands {
    font-size: 30px;
    transition: color 0.3s ease-in-out;
    color: #333;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &.fa-html5 {
      color: #e34c26;
    }

    &.fa-css3-alt {
      color: #0671b8;
    }

    &.fa-sass {
      color: #cd6799;
    }

    &.fa-git-alt {
      color: #f1502f;
    }

    &.fa-js {
      color: #f0db4f;
    }

    &.fa-react {
      color: #61dbfb;
    }

    &.fa-php {
      color: #5e81bd;
    }

    &.fa-wordpress {
      color: #00749c;
    }

    &.fa-magento {
      color: #f46f25;
    }
  }
}

.social-icons {
  display: flex;
  list-style: none;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  flex-wrap: wrap;

  .fa-brands {
    font-size: 30px;
    transition: color 0.3s ease-in-out;
    color: #333;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &.fa-github {
      color: #171515;
    }

    &.fa-gitlab {
      color: #e2432a;
    }
  }
}

.fa-linkedin {
  font-size: 20px;
  color: #4875B4;
  cursor: pointer;

  span {
    display: none;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($primary, .4);
}

.modal-content {
  background-color: $white;
  margin: auto;
  padding: 20px;
  border: 1px solid $primary;
  width: 80%;
  max-width: 450px;
  border-radius: 5px;
  position: relative;

  @media only screen and (min-width: 768px) {
    padding: 50px;
  }
}

.form-wrapper {
  .form {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;

    input, textarea {
      width: 100%;
      margin-bottom: 20px;
      max-width: 500px;
      padding: 10px 15px;
      border: solid 1px #ccc;
      border-radius: 3px;
    }

    textarea {
      height: 150px;
    }
  }
}

.close {
  color: $btn-yellow;
  float: right;
  font-size: 28px;
  position: absolute;
  top: -18px;
  right: -18px;
  transition: color 0.15s ease-in-out;

  &:hover,
  &:focus {
    color: $btn-yellow-hover;
    text-decoration: none;
    cursor: pointer;
  }
}

.grecaptcha-badge {
  display: none;
}
