*, body, html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
}

html, body {
  height: 100%;
}

a.btn, .btn {
  color: #1f1f1f;
  max-width: 150px;
  width: 100%;
  cursor: pointer;
  background-color: #fddb07;
  border: none;
  border-radius: 3px;
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  font-weight: 600;
  text-decoration: none;
  transition: background-color .15s ease-in-out;
  display: flex;
}

a.btn:hover, a.btn:active, a.btn:focus, .btn:hover, .btn:active, .btn:focus {
  background-color: #ccb200;
}

.mobile-only {
  display: block;
}

.desktop-only {
  display: none;
}

.logo img {
  width: 100%;
  max-width: 150px;
}

@media only screen and (min-width: 640px) {
  .logo img {
    max-width: 200px;
  }
}

@media only screen and (min-width: 992px) {
  .logo img {
    max-width: 250px;
  }
}

.header {
  text-align: center;
  background: radial-gradient(circle, #1f1f1f 0%, #000 100%);
  padding-top: 50px;
  padding-bottom: 50px;
}

.header h1 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .header h1 {
    font-size: 30px;
  }
}

.header h1 span {
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
}

.main-wrapper {
  height: 100%;
}

.content {
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
  padding: 40px 20px;
}

.content h1 {
  color: #343f7b;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.content h2 {
  color: #343f7b;
  padding-bottom: 20px;
  font-family: Source Serif Pro, cursive;
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
}

.content h2 span {
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
}

.content h3 {
  color: #333;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 30px;
}

.content h3.social-title {
  max-width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.content p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

@media only screen and (min-width: 768px) {
  .content p {
    font-size: 18px;
    line-height: 36px;
  }
}

.content .btn-github.fa-square-github {
  font-size: 20px;
}

.content .btn-github span {
  display: none;
}

.content .inner-content {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  .header {
    height: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .logo {
    font-size: 75px;
  }

  .content {
    padding: 40px;
  }

  .content h1 {
    padding-bottom: 20px;
    font-size: 40px;
    line-height: 40px;
  }
}

.dev-icons {
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

.dev-icons.social {
  margin-top: 20px;
}

.dev-icons .fa-brands {
  color: #333;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 30px;
  transition: color .3s ease-in-out;
}

.dev-icons .fa-brands.fa-html5 {
  color: #e34c26;
}

.dev-icons .fa-brands.fa-css3-alt {
  color: #0671b8;
}

.dev-icons .fa-brands.fa-sass {
  color: #cd6799;
}

.dev-icons .fa-brands.fa-git-alt {
  color: #f1502f;
}

.dev-icons .fa-brands.fa-js {
  color: #f0db4f;
}

.dev-icons .fa-brands.fa-react {
  color: #61dbfb;
}

.dev-icons .fa-brands.fa-php {
  color: #5e81bd;
}

.dev-icons .fa-brands.fa-wordpress {
  color: #00749c;
}

.dev-icons .fa-brands.fa-magento {
  color: #f46f25;
}

.social-icons {
  width: 100%;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  list-style: none;
  display: flex;
}

.social-icons .fa-brands {
  color: #333;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 30px;
  transition: color .3s ease-in-out;
}

.social-icons .fa-brands.fa-github {
  color: #171515;
}

.social-icons .fa-brands.fa-gitlab {
  color: #e2432a;
}

.fa-linkedin {
  color: #4875b4;
  cursor: pointer;
  font-size: 20px;
}

.fa-linkedin span {
  display: none;
}

.modal {
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #1f1f1f66;
  padding-top: 100px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  width: 80%;
  max-width: 450px;
  background-color: #fff;
  border: 1px solid #1f1f1f;
  border-radius: 5px;
  margin: auto;
  padding: 20px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .modal-content {
    padding: 50px;
  }
}

.form-wrapper .form {
  max-width: 500px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.form-wrapper .form input, .form-wrapper .form textarea {
  width: 100%;
  max-width: 500px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 10px 15px;
}

.form-wrapper .form textarea {
  height: 150px;
}

.close {
  color: #fddb07;
  float: right;
  font-size: 28px;
  transition: color .15s ease-in-out;
  position: absolute;
  top: -18px;
  right: -18px;
}

.close:hover, .close:focus {
  color: #ccb200;
  cursor: pointer;
  text-decoration: none;
}

.grecaptcha-badge {
  display: none;
}

/*# sourceMappingURL=index.3627e7e3.css.map */
